.App {
  text-align: center;
}

.main {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.login-box {
  width: 350px;
  height: 200px;
  border: solid 2px #ffffff;
  border-radius: 22px;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  background-color: #00000060;
  }

  .ctrl-box {
    width: 350px;
    height: 300px;
    border: solid 2px #ffffff;
    border-radius: 22px;
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: space-evenly;
    background-color: #00000060;
    position: relative;
    }